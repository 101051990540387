import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Input,
  Cell,
  Button,
  Radio,
  FilePicker,
  Icon,
  Toast,
  Badge,
  Picker,
  Select,
} from "zarm";
import "zarm/dist/zarm.css";

import FuncHeader from "components/FuncHeader/FuncHeader";
import { BASE_URL } from "../../../../utils/request/config";
const MAX_FILES_COUNT = 1;
export default function Wechat() {
  let token = useSelector((state) => state.auth.token);
  let userId = useSelector((state) => state.auth.userId);
  let userData = useSelector((state) => state.user?.data);
  let userWechat = useSelector((state) => state.user?.data?.wechat);
  let qr = useSelector((state) => state.user?.data?.wechatQRCode);

  const [files, setFiles] = useState([]);
  const history = useHistory();
  const [newWechat, setNewWechat] = useState("");

  const handleClick = async () => {
    if (newWechat) {
      var axios = require("axios");
      var data = JSON.stringify({
        UserName: userData?.username,
        Email: userData?.email,
        PhoneNumber: userData?.phoneNumber,
        CountryCode: userData?.countryCode,
        BankDetailCN: userData?.bankDetailCN,
        OverSeaBankDetail: userData?.overSeaBankDetail,
        AliPay: userData?.aliPay,
        Wechat: newWechat,
        BlockChainWalletAddress: userData?.blockChainWalletAddress,
      });

      var config = {
        method: "put",
        url: BASE_URL + "/UserProfile",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          history.push("/user");
        })
        .catch(function (error) {
          console.log(error);
        });
        if(files[0]){
          const file = files[0];
          var myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${token}`);
          var formdata = new FormData();
          formdata.append("PaymentType", "wechat");
          formdata.append("AttachedFile", file.file,
          file.fileName);
          var requestOptions = {
            method: "PUT",
            headers: myHeaders,
            body: formdata,
            redirect: "follow",
          };
          fetch(BASE_URL + "/UserProfile/payment_verification", requestOptions)
            .then((response) => response.json())
            .then((result) => {})
            .catch((error) => window.confirm("未知错误"));
        }
        
        
    } else {
      window.confirm("请输入新的微信号");
    }
  };
  const onSelect = (selFiles) => {
    const newFiles = files.concat(selFiles);
    if (newFiles.length > MAX_FILES_COUNT) {
      Toast.show("最多只能选择3张图片");
      return;
    }
    setFiles(newFiles);
  };

  const remove = (index) => {
    const newFiles = [].concat(files);
    newFiles.splice(index, 1);
    setFiles(newFiles);
    Toast.show("删除成功");
  };
  const imgRender = () => {
    return files.map((item, index) => {
      return (
        <Badge
          key={+index}
          className="file-picker-item"
          shape="circle"
          text={
            <span className="file-picker-closebtn">
              <Icon type="wrong" />
            </span>
          }
          onClick={() => remove(index)}
          style={{ padding: "10px" }}
        >
          <div className="file-picker-item-img">
            <a href={item.thumbnail} target="_blank" rel="noopener noreferrer">
              <img
                src={item.thumbnail}
                alt=""
                style={{
                  height: "60px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              />
            </a>
          </div>
        </Badge>
      );
    });
  };

  return (
    <>
      <FuncHeader
        title={"微信钱包"}
        goBack={() => history.push("/paymentMethod")}
      />
      <div style={{ marginTop: "60px" }}>
        <Cell title="我的微信">
          <Input
            readOnly
            type="text"
            defaultValue={userWechat ? userWechat : "未绑定"}
          />
        </Cell>
        <Cell title="微信QRcode">
          <img style={{width:"100%"}} src={qr?.split("&&")[0]}/>
        </Cell>
        <Cell title="更改信息">
          <Input
            clearable
            type="text"
            placeholder="新的微信账号"
            value={newWechat}
            onChange={(value) => {
              setNewWechat(value);
            }}
          />
        </Cell>
        <Cell title="双击上传微信二维码">
          <div className="file-picker-wrapper" style={{ marginTop: "10px" }}>
            {imgRender()}
            {files.length <= 1 && (
              <FilePicker
                multiple
                className="file-picker-btn"
                accept="image/*"
                onChange={onSelect}
              >
                <Icon type="add" size="lg" />
              </FilePicker>
            )}
          </div>
        </Cell>
        <Cell>
          <Button
            block
            theme="primary"
            style={{ marginTop: "20px" }}
            onClick={() => handleClick()}
          >
            提交
          </Button>
        </Cell>
      </div>
    </>
  );
}
