import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { CountryDropdown } from "react-country-region-selector";
import Autocomplete from "react-autocomplete";
import axios from "axios";
import { BASE_URL } from "../utils/request/config";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import FuncHeader from "components/FuncHeader/FuncHeader";
import Fuse from "fuse.js";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
export default function UserFilter() {
  const history = useHistory();
  const [country, setCountry] = useState("");
  const [shopName, setShopName] = useState("");
  const [username, setUsername] = useState("");
  const [usernameList, setUsernameList] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [companyNameist, setCompanyNameList] = useState([]);
  const [shopNameList, setShopNameList] = useState([]);
  const [shopFeatureList, setShopFeatureList] = useState([]);
  const [shopDesList, setShopDesList] = useState([]);
  const [shopDes, setShopDes] = useState("");
  const [shopFeature, setShopFeature] = useState("");
  const token = useSelector((state) => state.auth.token);
  const [users, setUsers] = useState([]);
  const [filterResult, setFilterResult] = useState([]);
  const [configs, setConfigs] = useState([]);
  const [mediaConfig, setMediaConfig] = useState([]);
  
  useEffect(() => {
    const getAllConfig = async () => {
      var config = {
        method: "GET",
        url: BASE_URL + "/Config",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      try {
        const response = await axios(config);

        if (response.data.data) {
          setConfigs(
            JSON.parse(
              response.data.data.find((n) => n.name === "AD_CONFIG")?.value ??
                `[]`
            )
          );
        }
      } catch (e) {}
    };
    const getAllMediaConfig = async () => {
      var config = {
        method: "GET",
        url: BASE_URL + "/Config/media",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      try {
        const response = await axios(config);

        if (response.data?.data) {
          setMediaConfig(
            response.data.data
          );
        }
      } catch (e) {}
    };
    const getAllUsers = async () => {
      var config = {
        method: "GET",
        url: BASE_URL + "/UserProfile",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      try {
        const response = await axios(config);
        const shops = response.data.data.filter(
          (n) => n.sellerVerified == true
        );
        
        let shopFeatureList = [];
        let shopDesList = [];
        const shopNameList = [];
        const usernameList = [];
        const conpanyNameList = [];
        shops.forEach((shop) => {
          if (shop.shopFeature) {
            shopFeatureList = shopFeatureList.concat(
              shop.shopFeature.split(/[,，]/)
            );
          }
          if (shop.shopName) {
            shopNameList.push(shop.shopName);
          }
          if (shop.username) {
            usernameList.push(shop.username);
          }
          if (shop.companyName) {
            usernameList.push(shop.companyName);
          }
          if (shop.shopDescription) {
            shopDesList = shopDesList.concat(
              shop.shopDescription.split(/[,，]/)
            );
          }
        });

        setShopNameList(
          _.uniq(shopNameList).map((n) => {
            return { label: n };
          })
        );
        setUsernameList(
          _.uniq(usernameList).map((n) => {
            return { label: n };
          })
        );
        setCompanyNameList(
          _.uniq(usernameList).map((n) => {
            return { label: n };
          })
        );
        setShopFeatureList(
          _.uniq(shopFeatureList).map((n) => {
            return { label: n };
          })
        );

        setShopDesList(
          _.uniq(shopDesList).map((n) => {
            return { label: n };
          })
        );

        setUsers(shops);
        setFilterResult(shops);
      } catch (e) {}
    };
    getAllUsers();
    getAllConfig();
    getAllMediaConfig()
  }, []);
  
  const fuzzySearch = (key, list, pattern) => {
    const options = { keys: [key] };
    const fuse = new Fuse(list, options);
    return fuse.search(pattern);
  };
  const handleSearch = () => {
    console.log("press");
    let current = [...users];
    if (country) {
      current = current.filter(
        (n) => n.country?.toLowerCase() == country.toLowerCase()
      );
    }
    if (username) {
      current = fuzzySearch("username", current, username).map((n) => n.item);
    }
    if (shopName) {
      current = fuzzySearch("shopName", current, shopName).map((n) => n.item);
    }
    if (shopFeature) {
      current = fuzzySearch("shopFeature", current, shopFeature).map(
        (n) => n.item
      );
    }
    if (companyName) {
      current = fuzzySearch("companyName", current, companyName).map((n) => n.item);
    }
    if (shopDes) {
      current = fuzzySearch("shopDescription", current, shopDes).map(
        (n) => n.item
      );
    }

    setFilterResult(current);
  };
 
  console.log(filterResult)
  return (
    <div>
      <FuncHeader title={"实体店搜索"} goBack={() => history.push("/user")} />
      <div style={{ paddingTop: "50px" }}>
        <AutoPlaySwipeableViews  enableMouseEvents>
          {configs.map((n) => {
            return (
              <img style={{ width: "100%", maxWidth: 500 }} src={n.imageUrl} />
            );
          })}
        </AutoPlaySwipeableViews>
      </div>
      <div style={{ display: "flex" }}>
        <div style={{ minWidth: 110, maxWidth: 200 }}>
          <div
            style={{
              height: "90vh",
              padding: 12,
              borderRight: "1px solid grey",
              minWidth: 100,
            }}
          >
            <p>国家</p>
            <CountryDropdown
              style={{ width: 90 }}
              value={country}
              onChange={(val) => setCountry(val)}
            />
            <br />
            <br />
            <p>店铺名</p>
            <Autocomplete
              inputProps={{style:{width:90}}}
              getItemValue={(item) => item.label}
              items={shopNameList}
              renderItem={(item, isHighlighted) => (
                <div
                  key={item.label}
                  style={{ background: isHighlighted ? "lightgray" : "white" }}
                >
                  {item.label}
                </div>
              )}
              value={shopName}
              onChange={(e) => setShopName(e.target.value)}
              onSelect={(val) => setShopName(val)}
            />
            <br />
            <br />
            <p>公司名</p>
            <Autocomplete
              inputProps={{style:{width:90}}}
              getItemValue={(item) => item.label}
              items={usernameList}
              renderItem={(item, isHighlighted) => (
                <div
                  key={item.label}
                  style={{ background: isHighlighted ? "lightgray" : "white" }}
                >
                  {item.label}
                </div>
              )}
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              onSelect={(val) => setCompanyName(val)}
            />
            <br />
            <br />
            <p>主营业务</p>
            <Autocomplete
              inputProps={{style:{width:90}}}
              getItemValue={(item) => item.label}
              items={shopDesList}
              renderItem={(item, isHighlighted) => (
                <div
                  key={item.label}
                  style={{ background: isHighlighted ? "lightgray" : "white" }}
                >
                  {item.label}
                </div>
              )}
              value={shopDes}
              onChange={(e) => setShopDes(e.target.value)}
              onSelect={(val) => setShopDes(val)}
            />
            <br />
            <br />
            <p>分享特色</p>
            <Autocomplete
              inputProps={{style:{width:90}}}
              getItemValue={(item) => item.label}
              items={shopFeatureList}
              renderItem={(item, isHighlighted) => (
                <div
                  key={item.label}
                  style={{ background: isHighlighted ? "lightgray" : "white" }}
                >
                  {item.label}
                </div>
              )}
              value={shopFeature}
              onChange={(e) => setShopFeature(e.target.value)}
              onSelect={(val) => setShopFeature(val)}
            />
            <br />
            <br />
            <button onClick={() => handleSearch()}>搜索</button>
          </div>
        </div>
        <div style={{ flex: 2, padding: 12 }}>
          <div
            style={{
              display: "flex",
              maxWidth: 275,
              // width: "100%",
              // flexFlow: "wrap",
              overflowX: "auto",
              height: "90vh",
            }}
          >
            {filterResult.slice(0,Math.ceil(filterResult.length/2) ).map((x,i) => {
              const n = filterResult[i];
              const next = filterResult[i + Math.ceil(filterResult.length/2) ]
                
                const shopImages = [mediaConfig?.find(
                  (c) => c.name === `${n.id}-SHOP-IMAGE-1`
                )?.value,mediaConfig?.find(
                  (c) => c.name === `${n.id}-SHOP-IMAGE-2`
                )?.value,mediaConfig?.find(
                  (c) => c.name === `${n.id}-SHOP-IMAGE-3`
                )?.value,mediaConfig?.find(
                  (c) => c.name === `${n.id}-SHOP-IMAGE-4`
                )?.value].filter(c=>c)

                const nextShopImages = next?  [mediaConfig?.find(
                  (c) => c.name === `${next.id}-SHOP-IMAGE-1`
                )?.value,mediaConfig?.find(
                  (c) => c.name === `${next.id}-SHOP-IMAGE-2`
                )?.value,mediaConfig?.find(
                  (c) => c.name === `${next.id}-SHOP-IMAGE-3`
                )?.value,mediaConfig?.find(
                  (c) => c.name === `${next.id}-SHOP-IMAGE-4`
                )?.value].filter(c=>c) :[]
               
              return (
                <div>
                <div
                  key={n.id}
                  style={{
                    width: 250,
                    height: 200,
                    border: "1px solid grey",
                    marginRight: 12,
                    borderRadius: 6,
                    marginBottom: 12,
                    display:'flex'
                  }}
                >
                   <div style={{ padding: 8, height: 200,width: 150, overflow: "auto" }}>
                    {/* <div>负责人: {n.username}</div> */}
                    <div style={{fontSize:16,fontWeight:500}}>店铺名: {n.shopName}</div>
                    <div style={{fontSize:8}}>国家: {n.country}</div>
                    <div>公司名: {n.companyName}</div>
                    

                    <div>主营业务: {n.shopDescription}</div>
                    <div>网站: {n.companyLink}</div>
                    <div style={{display:'flex'}}>邮箱: {n.email}</div>
                    <div>地址: {n.address?.split("-")[0]}</div>
                    <div>电话: {n.phoneNumber}</div>
                    <div>分享特色: {n.shopFeature}</div>
                  </div>
                  <div style={{width:100,height:200}}>
                  <img
                    style={{  width: 70, height: 70, borderRadius: 6,marginTop:20,marginRight:10 }}
                    src={
                      n.shopAvatarImage ||
                      "https://cva-api.s3-ap-southeast-2.amazonaws.com/mediaConfig/B22698B8-42A2-4115-9631-1C2D1E2AC5F7-SHOP-AVATAR-1638762962962/截屏2021-12-04 下午4.37.43.png"
                    }
                  />
                  
                 <div style={{width:90,height:90,marginTop:12, display:'flex',flex:2,flexWrap:'wrap'}}>
                   {
                     shopImages.map(s=>{
                       return <img
                       style={{  width: 45, height: 45, borderRadius: 6,flex:1 }}
                       src={s}
                     />
                     })
                   }

                 </div>

                  </div>
                 
                </div>
                {next&&<div
                  key={next.id}
                  style={{
                    width: 250,
                    height: 200,
                    border: "1px solid grey",
                    marginRight: 12,
                    borderRadius: 6,
                    marginBottom: 12,
                    display:'flex'
                  }}
                >
                   <div style={{ padding: 8, height: 200,width: 150, overflow: "auto" }}>
                    {/* <div>负责人: {n.username}</div> */}
                    <div style={{fontSize:16,fontWeight:500}}>店铺名: {next.shopName}</div>
                    <div style={{fontSize:8}}>国家: {next.country}</div>
                    <div>公司名: {next.companyName}</div>
                    

                    <div>主营业务: {next.shopDescription}</div>
                    <div>网站: {next.companyLink}</div>
                    <div style={{display:'flex'}}>邮箱: {next.email}</div>
                    <div>地址: {next.address?.split("-")[0]}</div>
                    <div>电话: {next.phoneNumber}</div>
                    <div>分享特色: {next.shopFeature}</div>
                  </div>
                  <div style={{width:100,height:200}}>
                  <img
                    style={{ width: 70, height: 70, borderRadius: 6,marginTop:20,marginRight:10 }}
                    src={
                      next.shopAvatarImage ||
                      "https://cva-api.s3-ap-southeast-2.amazonaws.com/mediaConfig/B22698B8-42A2-4115-9631-1C2D1E2AC5F7-SHOP-AVATAR-1638762962962/截屏2021-12-04 下午4.37.43.png"
                    }
                  />
                  <div style={{width:90,height:90,marginTop:12, display:'flex',flex:2,flexWrap:'wrap'}}>
                   {
                     nextShopImages.map(s=>{
                       return <img
                       style={{  width: 45, height: 45, borderRadius: 6,flex:1 }}
                       src={s}
                     />
                     })
                   }

                 </div>
                 </div>
                 
                </div>}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
