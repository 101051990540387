import React, { useState, useRef, useReducer, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";

import FuncHeader from "components/FuncHeader/FuncHeader";
import {
  Input,
  Cell,
  Button,
  Radio,
  FilePicker,
  Icon,
  Toast,
  Badge,
  Picker,
} from "zarm";
import "zarm/dist/zarm.css";
import { Request } from "../../../utils/request";
import {
  BASE_URL,
  CONTENT_TYPE,
  REQUEST_METHOD,
} from "../../../utils/request/config";

const MAX_FILES_COUNT = 1;

const PAY_TYPE = [
  { value: "1", label: "微信" },
  { value: "2", label: "支付宝" },
  { value: "3", label: "现金" },
  { value: "4", label: "海外银行" },
  { value: "5", label: "中国银行" },
];
const initState = {
  single: {
    visible: false,
    value: "",
    dataSource: PAY_TYPE,
  },
  specDOM: {
    visible: false,
    value: "",
    dataSource: PAY_TYPE,
  },
};


const reducer = (state, action) => {
  const { type, key, value } = action;

  // types seitcher
  switch (type) {
    case "visible":
      return {
        ...state,
        [key]: {
          ...state[key],
          visible: !state[key].visible,
        },
      };
    case "value":
      return {
        ...state,
        [key]: {
          ...state[key],
          value,
        },
      };
  }
};


export default function Recharge() {
  let token = useSelector((state) => state.auth.token);
  let userId = useSelector((state) => state.auth.userId);
  let userEmail = useSelector((state) => state.user?.data?.email);
  let userMobile = useSelector((state) => state.user?.data?.phoneNumber);
  let walletData = useSelector((state) => state.user?.data?.wallet);
  let {config} = useSelector((state) => state.user?.data);
  
  const bankConfig = _.groupBy(JSON.parse(config.find(n=>n.name == 'BANK_DETAIL_CONFIG')?.value||[]), "area");
  console.log(bankConfig)
  const renderAccount = (type) => {
    switch (type) {
      case "微信":
        return (
          <Cell title="微信钱包">
            <Input readOnly type="text" rows={3} value={config.find(c=>c.name == 'WECHAT_PAY_DETAIL')?.value} />
          </Cell>
        );
      case "支付宝":
        return (
          <Cell title="支付宝">
            <Input readOnly type="text" rows={3}  value={config.find(c=>c.name == 'ALIPAY_PAY_DETAIL')?.value}   />
          </Cell>
        );
      case "现金":
        return (
          <Cell title="现金">
            <Input
              readOnly
              type="text"
              rows={3}
              value={config.find(c=>c.name == 'CASH_PAY_DETAIL')?.value}  
            />
          </Cell>
        );
      case "中国银行":
        return (
          <Cell title="中国银行">
            <Input
              readOnly
              type="text"
              rows={3}
              value={config.find(c=>c.name == 'CHINESE_BANK_DETAIL')?.value}            />
          </Cell>
        );
      case "海外银行":
        return (
          <Cell title="海外银行">
            <Input
              readOnly
              type="text"
              rows={3}
              value={config.find(c=>c.name == 'OVERSEA_BANK_DETAIL')?.value}
            />
          </Cell>
        );
    }
  };
  const history = useHistory();
  const [coinType, setCoinType] = useState("cva");
  const [currencyType, setCurrencyType] = useState("AUD");
  const [payPassword, setPayPassword] = useState("");
  const [amount, setAmount] = useState("");
  const [files, setFiles] = useState([]);
  const [transactionMethod, setTransactionMethod] = useState("");
  const [bankName, setBankName] = useState("");
  const [transactionArea, setTransactionArea] = useState("");

  const myRef = useRef();
  const [state, dispatch] = useReducer(reducer, initState);
  const setVisible = (key) => {
    dispatch({ type: "visible", key });
  };
  const setValue = (key, value) => {
    dispatch({ type: "value", key, value });
  };

  const onSelect = (selFiles) => {
    const newFiles = files.concat(selFiles);
    if (newFiles.length > MAX_FILES_COUNT) {
      Toast.show("最多只能选择1张图片");
      return;
    }
    setFiles(newFiles);
  };

  const remove = (index) => {
    const newFiles = [].concat(files);
    newFiles.splice(index, 1);
    setFiles(newFiles);
    Toast.show("删除成功");
  };

  const imgRender = () => {
    return files.map((item, index) => {
      return (
        <Badge
          key={+index}
          className="file-picker-item"
          shape="circle"
          text={
            <span className="file-picker-closebtn">
              <Icon type="wrong" />
            </span>
          }
          onClick={() => remove(index)}
          style={{ padding: "10px" }}
        >
          <div className="file-picker-item-img">
            <a href={item.thumbnail} target="_blank" rel="noopener noreferrer">
              <img
                src={item.thumbnail}
                alt=""
                style={{
                  height: "60px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              />
            </a>
          </div>
        </Badge>
      );
    });
  };

  const sucessConfirm = (result) => {
    if (result.status == 401) {
      window.confirm("支付密码错误。");
    }
    if (result.status == "ERROR") {
      window.confirm("信息输入错误。");
    }
    if (result.status == "Success") {
      if (window.confirm("操作成功。")) {
        history.push("/user");
      }
    }
  };

  // hanlding submit

  const handleClick = async () => {
    if (files[0]?.file) {
      try {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);
        var formdata = new FormData();
        formdata.append("TransactionCoinType", coinType);
        formdata.append("Amount", amount);
        formdata.append("TransactionType", "RECHARGE");
        formdata.append("PayPassword", payPassword);
        formdata.append("AttachedFiles", files[0]?.file);
        formdata.append("TransactionMethod", transactionMethod);
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: formdata,
          redirect: "follow",
        };
        fetch(BASE_URL + "/Transaction", requestOptions)
          .then((response) => response.json())
          .then((result) => sucessConfirm(result))
          .catch((error) => window.confirm("未知错误"));
      } catch (error) {
        window.confirm("未知错误");
      }
    } else {
      window.confirm("请双击上传凭证。");
    }
  };
  const debounceHandleClick = _.throttle(handleClick, 8000, {
    leading: true,
    trailing: false,
  });

  const coinTypeSwitch = (type) => {
    switch (type) {
      case "cva":
        return `（CVD） ${walletData?.cva}`;
      // case "cvt":
      //   return `${walletData?.cvt}`;
      // case "abg":
      //   return `${walletData?.abg}`;
    }
  };
  console.log(bankConfig[transactionArea]?.filter(n=>n.accountType == transactionMethod))
  const selectedBank = bankConfig[transactionArea]?.filter(n=>n.accountType == transactionMethod)?.find(n=>n.bank == bankName)
  const bankList = _.groupBy( bankConfig[transactionArea]||[],'bank');
  return (
    <>
      <FuncHeader title={"线下充值"} goBack={() => history.push("/user")} />
      <div style={{ marginTop: "60px" }}>
        <Cell
          description={
            <Radio.Group
              type="button"
              value={coinType}
              onChange={(value) => {
                setCoinType(value);
              }}
            >
              <Radio value="cva">充入 CVD滴水池</Radio>
              {/*<Radio value="cvt">CVT提货通证</Radio>*/}
              {/*<Radio value="abg">ABG金豆</Radio>*/}
            </Radio.Group>
          }
        >
          充值类型
        </Cell>
        <Cell
          description={
            <Radio.Group
              type="button"
              value={currencyType}
              onChange={(value) => {
                setCurrencyType(value);
              }}
            >
              <Radio value="RMB">RMB</Radio>
              <Radio value="AUD">AUD</Radio>
              <Radio value="USD">USD</Radio>
            </Radio.Group>
          }
        >
          货币类型
        </Cell>
        <Cell title="当前余额">{coinTypeSwitch(coinType)}</Cell>
        <Cell title="充值金额">
          <Input
            type="price"
            value={amount}
            onChange={(value) => {
              setAmount(value);
            }}
            placeholder={"请输入充值金额"}
          />
        </Cell>
        <Cell title="充值CVD为">
          <div>{currencyType == 'USD'?amount*1.2:currencyType == 'RMB'? amount/5:amount}</div>
        </Cell>
        {/* <Cell
          description={
            <Button size="xs" onClick={() => setVisible("single")}>
              请点击选择
            </Button>
          }
        >
          支付方式
        </Cell> */}
        {/* <Picker
          visible={state.single.visible}
          value={state.single.value}
          dataSource={state.single.dataSource}
          onOk={(selected) => {
            Toast.show("您选择了" + selected[0]?.label);
            setTransactionMethod(selected[0]?.label);
            setValue(
              "single",
              selected.map((item) => item.value)
            );
            setVisible("single");
          }}
          onCancel={() => setVisible("single")}
        />
        {renderAccount(transactionMethod)} */}
        <Cell title="支付区域" description={<Radio.Group
              type="button"
              value={transactionArea}
              onChange={(value) => {
                setTransactionArea(value);
              }}
            >
              {Object.keys(bankConfig).map(n=><Radio value={n}>{n}</Radio>)}
             
            </Radio.Group>}>
        
            </Cell>
            <Cell title="支付方式" description={<Radio.Group
              type="button"
              value={transactionMethod}
              onChange={(value) => {
                setTransactionMethod(value);
              }}
            >
              {(bankConfig[transactionArea]||[]).map(n=><Radio value={n.accountType}>{n.accountType}</Radio>)}
             
            </Radio.Group>}>
            
              
        
            </Cell>
            {Object.keys(bankList).length>0&& <Cell title="银行" description={<Radio.Group
              type="button"
              value={bankName}
              onChange={(value) => {
                setBankName(value);
              }}
            >
              {Object.keys(bankList).map(n=><Radio value={n}>{n}</Radio>)}
             
            </Radio.Group>}>
              </Cell>}
            { selectedBank &&
                <>
                
                 {selectedBank.accountName && <Cell title="账户名" description={selectedBank.accountName}>
                  </Cell>}
                  {selectedBank.bsb&& <Cell title="BSB" description={selectedBank.bsb}>
                  </Cell>}
                  {selectedBank.accountNumber && <Cell title="卡号" description={selectedBank.accountNumber}>
                  </Cell>}
                  {selectedBank.swiftCode && <Cell title="Swift Code" description={selectedBank.swiftCode}>
                  </Cell>}
                  {selectedBank.branch && <Cell title="支行" description={selectedBank.branch}>
                  </Cell>}
                  {selectedBank.qrcode && <Cell title="二维码" description={<img style={{width:50}} src={selectedBank.qrcode}/>}> </Cell>}
                </>
              }
        <Cell title="充值凭证">
          <div className="file-picker-wrapper" style={{ marginTop: "10px" }}>
            {imgRender()}
            {files.length < MAX_FILES_COUNT && (
              <FilePicker
                multiple
                className="file-picker-btn"
                accept="image/*"
                // onBeforeSelect={onBeforeSelect}
                onChange={onSelect}
              >
                <Icon type="add" size="lg" />
              </FilePicker>
            )}
          </div>
          {/*<Input*/}
          {/*    type="text"*/}
          {/*    value={toUserName}*/}
          {/*    onChange={(value) => {*/}
          {/*      setToUserName(value);*/}
          {/*    }}*/}
          {/*    placeholder={"请输入对方用户名"}*/}
          {/*/>*/}
        </Cell>
        {/*<Cell*/}
        {/*  description={*/}
        {/*    <Radio.Group*/}
        {/*      type="button"*/}
        {/*      value={verifyType}*/}
        {/*      onChange={(value) => {*/}
        {/*        setVerifyType(value);*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      <Radio value="email">邮箱</Radio>*/}
        {/*      <Radio value="mobile">手机</Radio>*/}
        {/*    </Radio.Group>*/}
        {/*  }*/}
        {/*>*/}
        {/*  接收验证码*/}
        {/*</Cell>*/}
        {/*<Cell>*/}
        {/*  <Button*/}
        {/*    block*/}
        {/*    style={{ marginTop: "20px", marginBottom: "20px" }}*/}
        {/*    onClick={() => getCode()}*/}
        {/*  >*/}
        {/*    获取验证码*/}
        {/*  </Button>*/}
        {/*</Cell>*/}
        <Cell title="支付密码">
          <Input
            type="password"
            value={payPassword}
            onChange={(value) => {
              setPayPassword(value);
            }}
            placeholder={"请输入支付密码"}
          />
        </Cell>
        {/*<Cell title="支付密码">*/}
        {/*  <Input*/}
        {/*      type="password"*/}
        {/*      value={payPassword}*/}
        {/*      onChange={(value) => {*/}
        {/*        setPayPassword(value);*/}
        {/*      }}*/}
        {/*      placeholder={"请输入支付密码"}*/}
        {/*  />*/}
        {/*</Cell>*/}
        <Cell>
          <Button
            block
            theme="primary"
            style={{ marginTop: "20px" }}
            onClick={() => debounceHandleClick()}
          >
            提交充值
          </Button>
        </Cell>
      </div>
    </>
  );
}
