import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as QueryString from "query-string";
import { Input, Cell, Button, Radio } from "zarm";
import "zarm/dist/zarm.css";

import FuncHeader from "components/FuncHeader/FuncHeader";
import { Request } from "../../utils/request";
import { CONTENT_TYPE, REQUEST_METHOD } from "../../utils/request/config";

let id;
let areaType;

export default function BuyBlock() {
  let token = useSelector((state) => state.auth.token);
  let {wallet,config} = useSelector((state) => state.user.data);
  console.log(config)
  let userId = useSelector((state) => state.auth.userId);
  const history = useHistory();
  const [payPassword, setPayPassword] = useState("");
  const [coinType, setCoinType] = useState("Cva");
  //get id from url
  id = QueryString.parse(window.location.search, { ignoreQueryPrefix: true })
    .id;
  areaType = QueryString.parse(window.location.search, {
    ignoreQueryPrefix: true,
  }).areaType;

  const handleClick = async () => {
    if (payPassword) {
      let request = new Request();
      try {
        let response = await request.fetchData("/Order", {
          method: REQUEST_METHOD.POST,
          contentType: CONTENT_TYPE.JSON,
          token,
          body: {
            AreaClaimId: id,
            PayPassword: payPassword,
            PaymentType: coinType,
          },
        });
        window.confirm("购买成功");
        history.push("/");
      } catch (error) {
        window.confirm("错误，请联系管理员。");
      }
    } else {
      window.confirm("请输入支付密码");
    }
  };

  const shuoMing = (type) => {
    switch (type) {
      case "1":
        return (
          <Cell title="说明">
            <div dangerouslySetInnerHTML={{__html:config.find(n=>n.name == `DESCRIPTION_${type}`)?.value||""}}>
              
            </div>
          </Cell>
        );
        case "2":
        return (
          <Cell title="说明">
            <div dangerouslySetInnerHTML={{__html:config.find(n=>n.name == `DESCRIPTION_${type}`)?.value||""}}>
              
            </div>
          </Cell>
        );
      case "A":
        return (
          <Cell title="说明">
            <div dangerouslySetInnerHTML={{__html:config.find(n=>n.name == `DESCRIPTION_${type}`)?.value||""}}>
              
            </div>
          </Cell>
        );
      case "B":
        return (
          <Cell title="说明">
           <div dangerouslySetInnerHTML={{__html:config.find(n=>n.name == `DESCRIPTION_${type}`)?.value||""}}>
              
              </div>
          </Cell>
        );
      case "C":
        return (
          <Cell title="说明">
            <div dangerouslySetInnerHTML={{__html:config.find(n=>n.name == `DESCRIPTION_${type}`)?.value||""}}>
              
              </div>
          </Cell>
        );
      case "D":
        return (
          <Cell title="说明">
            <div dangerouslySetInnerHTML={{__html:config.find(n=>n.name == `DESCRIPTION_${type}`)?.value||""}}>
              
              </div>
          </Cell>
        );
      case "E":
        return (
          <Cell title="说明">
            <div dangerouslySetInnerHTML={{__html:config.find(n=>n.name == `DESCRIPTION_${type}`)?.value||""}}>
              
              </div>
          </Cell>
        );
      case "VIP":
        return (
          <Cell title="说明">
           <div dangerouslySetInnerHTML={{__html:config.find(n=>n.name == `DESCRIPTION_F`)?.value||""}}>
              
              </div>
          </Cell>
        );
      case "县代表":
        return (
          <Cell title="说明">
            <div dangerouslySetInnerHTML={{__html:config.find(n=>n.name == `DESCRIPTION_G`)?.value||""}}>
              
              </div>
          </Cell>
        );
      case "市代表":
        return (
          <Cell title="说明">
            <div dangerouslySetInnerHTML={{__html:config.find(n=>n.name == `DESCRIPTION_H`)?.value||""}}>
              
              </div>
          </Cell>
        );
      case "省代表":
        return (
          <Cell title="说明">
            <div dangerouslySetInnerHTML={{__html:config.find(n=>n.name == `DESCRIPTION_I`)?.value||""}}>
              
              </div>
          </Cell>
        );
      case "国家代表":
        return (
          <Cell title="说明">
            <div dangerouslySetInnerHTML={{__html:config.find(n=>n.name == `DESCRIPTION_J`)?.value||""}}>
              
              </div>
          </Cell>
        );
      case "洲域代表(全球七大洲)":
        return (
          <Cell title="说明">
           <div dangerouslySetInnerHTML={{__html:config.find(n=>n.name == `DESCRIPTION_K`)?.value||""}}>
              
              </div>
          </Cell>
        );
    }
  };

  return (
    <>
      <FuncHeader title={"购买套餐"} goBack={() => history.goBack()} />
      <div style={{ marginTop: "60px" }}>
        <Cell title="区块类型">
          <Input readOnly type="text" defaultValue={areaType + "区"} />
        </Cell>
        <Cell
          description={
            <Radio.Group
              type="button"
              value={coinType}
              onChange={(value) => {
                setCoinType(value);
              }}
            >
              <Radio value="Cva">CVD</Radio>
              <Radio value="Rpt">RPT</Radio>
              {/*<Radio value="abg">ABG金豆</Radio>*/}
            </Radio.Group>
          }
        >
          支付类型
        </Cell>
        <Cell title="支付密码">
          <Input
            type="password"
            value={payPassword}
            onChange={(value) => {
              setPayPassword(value);
            }}
            placeholder={"请输入支付密码"}
          />
        </Cell>
        {shuoMing(areaType)}
        <Cell>
          <div style={{display:'flex',width:"100%"}}>
          <div>您的滴水池有: {wallet.cva}CVD</div>
          <div style={{flex:1}}/><a href={"/recharge"}>去充值</a>
          </div>
        </Cell>
        <Cell>
          <div>
          您的可用RPT为：
         {wallet.rpt}
          </div>
        </Cell>
        <Cell>
          <Button
            block
            theme="primary"
            style={{ marginTop: "20px" }}
            onClick={() => handleClick()}
          >
            确认购买
          </Button>
        </Cell>
      </div>
    </>
  );
}
