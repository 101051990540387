import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Tabs, Cell, Radio, Button, Picker, Input } from "zarm";
import "zarm/dist/zarm.css";

import FuncHeader from "components/FuncHeader/FuncHeader";
import { Request } from "../../../../utils/request";
import {
  BASE_URL,
  CONTENT_TYPE,
  REQUEST_METHOD,
} from "../../../../utils/request/config";
import { CountryDropdown } from "react-country-region-selector";
export default function ShangChengGengxin() {
  var axios = require("axios");
  const history = useHistory();
  let token = useSelector((state) => state.auth.token);
  let userId = useSelector((state) => state.auth.userId);
  let userData = useSelector((state) => state.user?.data);
  console.log(userData);
  const shopMedia1 = userData?.mediaConfig?.find(
    (n) => n.name === `${userData.id}-SHOP-IMAGE-1`
  );
  const shopMedia2 = userData?.mediaConfig?.find(
    (n) => n.name === `${userData.id}-SHOP-IMAGE-2`
  );
  const shopMedia3 = userData?.mediaConfig?.find(
    (n) => n.name === `${userData.id}-SHOP-IMAGE-3`
  );
  const shopMedia4 = userData?.mediaConfig?.find(
    (n) => n.name === `${userData.id}-SHOP-IMAGE-4`
  );
  const [shopImages, setShopImages] = useState({});
  const [country, setCountry] = useState(userData?.country || "");
  const [companyName, setcompanyName] = useState(userData?.companyName);
  const [shopName, setshopName] = useState(userData?.shopName);
  const [companyLink, setcompanyLink] = useState(userData?.companyLink);
  const [address, setaddress] = useState(userData?.address);
  const [shopFeature, setshopFeature] = useState(userData?.shopFeature);
  const [shopDescription, setshopDescription] = useState(
    userData?.shopDescription
  );
  const [email, setemail] = useState(userData?.email);
  const [avatar, setAvator] = useState();
  const [rptRate, setrptRate] = useState(
    userData?.rptRate ? parseFloat(userData?.rptRate) * 100 : 0
  );
  // const [companyDescription, setcompanyDescription] = useState(
  //   userData?.address
  // );
  const [phoneNumber, setphoneNumber] = useState(userData?.phoneNumber);

  // useEffect(() => {
  //
  // });
  const uploadAvatar = async () => {
    try {
      var data = new FormData();
      data.append("Name", `${userData.id}-SHOP-AVATAR-${new Date().getTime()}`);
      data.append("Images", avatar);
      data.append("Description", avatar?.name);
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await axios.post(
        BASE_URL + "/Config/media",
        data,
        config
      );
      return response.data.data.value;
    } catch (e) {
      alert("系统上传图片失败，请稍后再试");
    }
  };

  const uploadShopImage = async (index) => {
    try {
      var data = new FormData();
      data.append("Name", `${userData.id}-SHOP-IMAGE-${index}`);
      data.append("Images", shopImages[index]);
      data.append("Description", shopImages[index]?.name);
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await axios.post(
        BASE_URL + "/Config/media",
        data,
        config
      );
      return 'success';
    } catch (e) {
      alert("系统上传图片失败，请稍后再试");
    }
  };
  const handleClick = async () => {
    var data = {
      ...userData,
      UserName: userData?.username,
      Email: email,
      CompanyName: companyName,
      ShopName: shopName,
      CompanyLink: companyLink,
      Address: address,
      shopDescription: shopDescription,
      RptRate: rptRate / 100,
      PhoneNumber: phoneNumber,
      shopFeature: shopFeature,
      Country: country,
    };
    for(let index in shopImages){
      await uploadShopImage(index)
    }
    if (avatar) {
      const shopAvatarImage = await uploadAvatar();
      
      if (shopAvatarImage) {
        data.shopAvatarImage = shopAvatarImage;
      }
    }

    var config = {
      method: "put",
      url: BASE_URL + "/UserProfile",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        history.push("/user");
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const handleSetImage = (index,file)=>{
    setShopImages({...shopImages, [index]: file});
  }
 
  return (
    <>
      <FuncHeader title={"商城信息"} goBack={() => history.push("/user")} />
      <div style={{ paddingTop: "50px" }}>
        <Cell
          description={
            <div className="box">
              <Input
                clearable
                type="text"
                placeholder={userData?.companyName}
                value={companyName}
                onChange={(value) => {
                  setcompanyName(value);
                }}
              />
            </div>
          }
        >
          公司名
        </Cell>
        <Cell
          description={
            <CountryDropdown
              value={country}
              onChange={(val) => setCountry(val)}
            />
          }
        >
          国家
        </Cell>
        <Cell
          description={
            <div className="box">
              <Input
                clearable
                type="text"
                placeholder={userData?.shopName}
                value={shopName}
                onChange={(value) => {
                  setshopName(value);
                }}
              />
            </div>
          }
        >
          商店名
        </Cell>
        <Cell
          description={
            <div className="box">
              {userData.shopAvatarImage && (
                <img
                  src={userData.shopAvatarImage}
                  style={{ width: 50, height: 50 }}
                />
              )}
              <input
                type="file"
                accept="image/*"
                onChange={(e) => {
                  setAvator(e.target.files[0]);
                }}
              />
            </div>
          }
        >
          商铺图片
        </Cell>
        <Cell
          description={
            <div className="box">
              {shopMedia1 && (
                <img src={shopMedia1.value} style={{ width: 50, height: 50 }} />
              )}
              <input
                type="file"
                accept="image/*"
                onChange={(e) => {
                  handleSetImage(1,e.target.files[0])
                }}
              />
            </div>
          }
        >
          商铺细节图片1
        </Cell>
        <Cell
          description={
            <div className="box">
              {shopMedia2 && (
                <img src={shopMedia2.value} style={{ width: 50, height: 50 }} />
              )}
              <input
                type="file"
                accept="image/*"
                onChange={(e) => {
                  handleSetImage(2,e.target.files[0])
                }}
              />
            </div>
          }
        >
          商铺细节图片2
        </Cell>
        <Cell
          description={
            <div className="box">
              {shopMedia3 && (
                <img src={shopMedia3.value} style={{ width: 50, height: 50 }} />
              )}
              <input
                type="file"
                accept="image/*"
                onChange={(e) => {
                  handleSetImage(3,e.target.files[0])
                }}
              />
            </div>
          }
        >
          商铺细节图片3
        </Cell>
        <Cell
          description={
            <div className="box">
              {shopMedia4 && (
                <img src={shopMedia4.value} style={{ width: 50, height: 50 }} />
              )}
              <input
                type="file"
                accept="image/*"
                onChange={(e) => {
                  handleSetImage(4,e.target.files[0])
                }}
              />
            </div>
          }
        >
          商铺细节图片4
        </Cell>
        <Cell
          description={
            <div className="box">
              <Input
                clearable
                type="text"
                placeholder={userData?.companyLink}
                value={companyLink}
                onChange={(value) => {
                  setcompanyLink(value);
                }}
              />
            </div>
          }
        >
          公司网站
        </Cell>
        <Cell
          description={
            <div className="box">
              <Input
                clearable
                type="text"
                placeholder={userData?.address}
                value={address}
                onChange={(value) => {
                  setaddress(value);
                }}
              />
            </div>
          }
        >
          地址
        </Cell>
        <Cell
          description={
            <div className="box">
              <Input
                clearable
                type="text"
                value={shopDescription}
                onChange={(value) => {
                  setshopDescription(value);
                }}
              />
            </div>
          }
        >
          商铺主营业务
        </Cell>
        <Cell
          description={
            <div className="box">
              <Input
                clearable
                type="text"
                value={shopFeature}
                onChange={(value) => {
                  setshopFeature(value);
                }}
              />
            </div>
          }
        >
          分享特色
        </Cell>

        <Cell
          description={
            <div className="box">
              <Input
                clearable
                type="text"
                value={phoneNumber}
                onChange={(value) => {
                  setphoneNumber(value);
                }}
              />
            </div>
          }
        >
          手机号
        </Cell>
        <Cell
          description={
            <div className="box">
              <Input
                clearable
                type="text"
                value={email}
                onChange={(value) => {
                  setemail(value);
                }}
              />
            </div>
          }
        >
          企业邮箱
        </Cell>
        <Cell
          description={
            <input
              type="number"
              value={rptRate}
              onChange={(e) => {
                setrptRate(e.target.value);
              }}
            />
          }
          title={" 回佣比例(%)"}
        ></Cell>
        {/*<Cell description={<div className="box">*/}
        {/*  <Input*/}
        {/*      clearable*/}
        {/*      type="text"*/}
        {/*      placeholder={userData?.phoneNumber}*/}
        {/*      value={phoneNumber}*/}
        {/*      onChange={(value) => {*/}
        {/*        setphoneNumber(value);*/}
        {/*      }}*/}
        {/*  />*/}
        {/*</div>}>*/}
        {/*  手机*/}
        {/*</Cell>*/}
        <Cell>
          <Button
            block
            theme="primary"
            style={{ marginTop: "20px" }}
            onClick={() => handleClick()}
          >
            更改商家信息
          </Button>
        </Cell>
      </div>
    </>
  );
}
