import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import FuncHeader from "components/FuncHeader/FuncHeader";

export default function Gongyi() {
  const history = useHistory();
  // todo
  return (
    <>
      <FuncHeader title={"慈善公益"} goBack={() => history.push("/user")} />
      <div style={{ display: "flex", justifyContent: "center",marginTop:50 }}>
        <img style={{width:'100%'}} src={'https://cva-api.s3-ap-southeast-2.amazonaws.com/mediaConfig/cishan/cishan.jpeg'}/>
      </div>
    </>
  );
}
