import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import FuncHeader from "components/FuncHeader/FuncHeader";
import { useSelector, useDispatch } from "react-redux";
import * as actionCreators from "store/actionCreators";
import { useForm } from "react-hook-form";
import { Request } from "utils/request/index";
import { REQUEST_METHOD, CONTENT_TYPE } from "utils/request/config";
import * as styles from "./register.module.scss";
import * as QueryString from "query-string";
import Vcode from "react-vcode";
import { Tabs, Cell, Radio, Button, Picker, Input,Select } from "zarm";
import "zarm/dist/zarm.css";

let referC = null;

export default function Register(props) {
  const [account, setAccount] = useState("");
  const [pwd, setPwd] = useState("");
  const [mobile, setMobile] = useState("");
  const [verifyCode, setVerifyCode] = useState("");
  const [type, setType] = useState("email");
  const [referCode, setReferCode] = useState("");
  const [payPassword, setPayPassword] = useState("");
  const [vcode, setVcode] = useState(null);
  const [inputVcode, setInputVcode] = useState(null);
  const [seconds, setSeconds] = useState(60);
  const [resend, setResend] = useState(false);
  const [agree, setAgree] = useState(true);
  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setSeconds(60);
      setResend(false);
    }
  });
  const dispatch = useDispatch();

  const { register, handleSubmit, errors } = useForm();

  const history = useHistory();
  //get code from url, invite code
  referC = QueryString.parse(window.location.search, {
    ignoreQueryPrefix: true,
  }).referC;

  const goValidation = () => {
    history.push("/validation");
  };

  const referCodeS = () => {
    if (referCode) {
      return referCode;
    } else {
      return referC;
    }
  };

  const sendEmail = (email) => {
    let request = new Request();
    try {
      let response = request.fetchData("/UserProfile/regist/email_code", {
        method: REQUEST_METHOD.POST,
        contentType: CONTENT_TYPE.JSON,

        body: {
          Email: email,
        },
      });
      window.confirm("验证码已发送到邮箱。");
      setResend(true);
    } catch (error) {
      window.confirm("输入有误，请检查email格式是否正确。");
    }
  };

  // handling registriong
  const handleClick = async () => {
    if (inputVcode === vcode) {
      let request = new Request();
      try {
        let response = await request.fetchData("/authenticate/register", {
          method: REQUEST_METHOD.POST,
          contentType: CONTENT_TYPE.JSON,
          body: {
            username: account,
            password: pwd,
            ReferCode: referCodeS(),
            PayPassword: payPassword,
            VerifyCode: verifyCode,
            VerifyType: type,
            Email: type === 'email' ? mobile:  undefined,
            Mobile: type === 'mobile' ? mobile:  undefined,
          },
        });
        dispatch(actionCreators.register());
        let responseLogin = await request.fetchData("/authenticate/login", {
          method: REQUEST_METHOD.POST,
          contentType: CONTENT_TYPE.JSON,
          body: {
            username: account,
            password: pwd,
          },
        });
        dispatch(
          actionCreators.login(responseLogin.token, responseLogin.userId)
        );
        window.confirm("注册成功，请验证手机或者邮箱。");
        goValidation();
      } catch (error) {
        
        // check password
        if (error.message === "User already exists!") {
          window.confirm("用户名已占用。");
        } else if (
          error.message ==
          '[{"Code":"PasswordRequiresNonAlphanumeric","Description":"Passwords must have at least one non alphanumeric character."},{"Code":"PasswordRequiresLower","Description":"Passwords must have at least one lowercase (\'a\'-\'z\')."}]'
        ) {
          window.confirm(
            "密码过于简单，请输入6-16位，且包含大写，小写，数字。"
          );
        } else if (
          error.message ==
          '[{"Code":"PasswordRequiresNonAlphanumeric","Description":"Passwords must have at least one non alphanumeric character."},{"Code":"PasswordRequiresLower","Description":"Passwords must have at least one lowercase (\'a\'-\'z\')."},{"Code":"PasswordRequiresUpper","Description":"Passwords must have at least one uppercase (\'A\'-\'Z\')."}]'
        ) {
          window.confirm(
            "密码过于简单，请输入6-16位，且包含大写，小写，数字。"
          );
        } else if (
          error.message ==
          'Invalid Code'
        ) {
    
          window.confirm(
            "验证码输入有误"
          );
        }
        else if (
          error.message ==
          'Email exist'
        ) {
          
          window.confirm(
            "邮箱已存在，请更换另外邮箱"
          );
        }else if (
          error.message ==
          'Mobile exist'
        ) {
          
          window.confirm(
            "手机号已存在，请更换另外手机号"
          );
        } else {
          window.confirm("未知错误，请联系管理员。");
        }
      }
    } else {
      window.confirm("验证码输入有误。");
    }
  };

  const sendSMS = (phone) => {
    let request = new Request();
    try {
      let response = request.fetchData("/UserProfile/regist/sms_code", {
        method: REQUEST_METHOD.POST,
        contentType: CONTENT_TYPE.JSON,     
        body: {
          Mobile: phone,
        },
      });
      window.confirm("验证码已发送到手机。");
      setResend(true);
    } catch (error) {
     
      window.confirm(
        "输入有误，请检查手机格式是否正确。注意，澳洲手机号不需要输入0。"
      );
    }
  };

  const getCode = () => {
    if (mobile) {
      if (type === "email") {
        sendEmail(mobile);
      } else {
        sendSMS(mobile);
      }
    } else {
      window.confirm("请输入邮箱或手机。");
    }
  };

  return (
    <div className={styles["wrapper"]}>
      <FuncHeader title={"新用户注册"} goBack={() => history.push("/login")} />
      <div>
        <Cell title="用户名">
          <Input
            type="text"
            value={account}
            onChange={(value) => {
              setAccount(value);
            }}
            placeholder={"请输入用户名"}
          />
        </Cell>
        <Cell title="登陆密码">
          <Input
            type="password"
            value={pwd}
            onChange={(value) => {
              setPwd(value);
            }}
            placeholder={"请输入登陆密码"}
          />
        </Cell>
        <Cell>
          <p>密码要求长度6-12位，并包含大写，小写字母，数字。</p>
        </Cell>
        <Cell title="分享码">
          <Input
            type="text"
            value={referC ? referC : referCode}
            onChange={(value) => {
              setReferCode(value);
            }}
            placeholder={"请输入分享码"}
          />
        </Cell>
        <Cell title="支付密码">
          <Input
            type="password"
            value={payPassword}
            onChange={(value) => {
              setPayPassword(value);
            }}
            placeholder={"请输入支付密码"}
          />
        </Cell>
        <Cell title="验证方式">
        <Select
              value={type}
              dataSource={[{label:"手机号",value:"mobile"},{label:"邮箱",value:"email"},]}
              onOk={(selected) => {
                setType(selected[0].value)
              }}
            />
            
        </Cell>
        <Cell title="">
          <Input
            
            value={mobile}
            onChange={(value) => {
              setMobile(value);
            }}
            placeholder={"请输入手机号（+区号 e.g. +614xxxx）或邮箱"}
          />
        </Cell>
        <Cell description={ <button onClick={()=>{!resend&&getCode()}}>{resend ? `等待${seconds}秒重发` : "获取验证码"}</button>} title="">
       
          <Input
            type="text"
            value={verifyCode}
            onChange={(value) => {
              setVerifyCode(value);
            }}
            placeholder={"输入短信/邮箱验证码"}
          />
        </Cell>
        <Vcode
          length={4}
          onChange={(v) => setVcode(v)}
          options={{
            codes: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"],
          }}
          style={{ marginBottom: "20px" }}
        />
        <Cell title="验证码">
          <Input
            type="text"
            value={inputVcode}
            onChange={(value) => {
              setInputVcode(value);
            }}
            placeholder={"请输入验证码"}
          />
        </Cell>
        <Cell description={<div>我已同意并阅读<a href="/Baipishu">《用户协议》</a></div>} title="">
          <input checked={agree} onClick={()=>setAgree(!agree)} type="checkbox"/>
        </Cell>
        <Cell>
          <Button
            disabled={!agree}
            block
            theme="primary"
            style={{ marginTop: "20px" }}
            onClick={() => handleClick()}
          >
            下一步
          </Button>
        </Cell>
        {/*<form*/}
        {/*  className={styles["login-form"]}*/}
        {/*  onSubmit={handleSubmit(handleClick)}*/}
        {/*>*/}
        {/*  <input*/}
        {/*    id="account"*/}
        {/*    name="username"*/}
        {/*    defaultValue={account}*/}
        {/*    onChange={(e) => setAccount(e.currentTarget.value)}*/}
        {/*    placeholder="用户名"*/}
        {/*    ref={register({ required: true })}*/}
        {/*  />*/}
        {/*  {errors.username?.type === "required" && (*/}
        {/*    <span style={{ marginTop: "-10px", color: "red" }}>*/}
        {/*      请输入用户名*/}
        {/*    </span>*/}
        {/*  )}*/}

        {/*  <input*/}
        {/*    id="password"*/}
        {/*    name="password"*/}
        {/*    defaultValue={pwd}*/}
        {/*    onChange={(e) => setPwd(e.currentTarget.value)}*/}
        {/*    placeholder="登陆密码"*/}
        {/*    ref={register({*/}
        {/*      required: true,*/}
        {/*      pattern: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/,*/}
        {/*    })}*/}
        {/*  />*/}
        {/*  {errors.password?.type === "required" && (*/}
        {/*    <span style={{ marginTop: "-10px", color: "red" }}>请输入密码</span>*/}
        {/*  )}*/}
        {/*  {errors.password?.type === "pattern" && (*/}
        {/*    <span style={{ marginTop: "-10px", color: "red" }}>*/}
        {/*      密码格式为6-16位，必须包含数字，特殊字符，大小写字母*/}
        {/*    </span>*/}
        {/*  )}*/}

        {/*  <input*/}
        {/*    id="referCode"*/}
        {/*    name="referCode"*/}
        {/*    defaultValue={referC}*/}
        {/*    onChange={(e) => setReferCode(e.currentTarget.value)}*/}
        {/*    placeholder="分享码"*/}
        {/*    ref={register({ required: true })}*/}
        {/*  />*/}
        {/*  {errors.referCode?.type === "required" && (*/}
        {/*    <span style={{ marginTop: "-10px", color: "red" }}>*/}
        {/*      请输入分享码*/}
        {/*    </span>*/}
        {/*  )}*/}

        {/*  <input*/}
        {/*    id="payPassword"*/}
        {/*    name="payPassword"*/}
        {/*    defaultValue={payPassword}*/}
        {/*    onChange={(e) => setPayPassword(e.currentTarget.value)}*/}
        {/*    placeholder="支付密码"*/}
        {/*    ref={register({*/}
        {/*      required: true,*/}
        {/*      pattern: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/,*/}
        {/*    })}*/}
        {/*  />*/}
        {/*  {errors.password?.type === "required" && (*/}
        {/*    <span style={{ marginTop: "-10px", color: "red" }}>请输入密码</span>*/}
        {/*  )}*/}
        {/*  {errors.password?.type === "pattern" && (*/}
        {/*    <span style={{ marginTop: "-10px", color: "red" }}>*/}
        {/*      密码格式为6-16位，必须包含数字，特殊字符，大小写字母*/}
        {/*    </span>*/}
        {/*  )}*/}
        {/*  <input*/}
        {/*    name="vcode"*/}
        {/*    onChange={(e) => setInputVcode(e.currentTarget.value)}*/}
        {/*    placeholder="验证码"*/}
        {/*  />*/}
        {/*  {errors.vcode?.type === "required" && (*/}
        {/*    <span style={{ marginTop: "-10px", color: "red" }}>*/}
        {/*      请输入验证码*/}
        {/*    </span>*/}
        {/*  )}*/}
        {/*  <Vcode*/}
        {/*    length={4}*/}
        {/*    onChange={(v) => setVcode(v)}*/}
        {/*    options={{*/}
        {/*      codes: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"],*/}
        {/*    }}*/}
        {/*    style={{ marginBottom: "20px" }}*/}
        {/*  />*/}
        {/*  <input*/}
        {/*    type="submit"*/}
        {/*    className={styles["login-form-btn"]}*/}
        {/*    value="下一步"*/}
        {/*  />*/}
        {/*</form>*/}
      </div>
    </div>
  );
}
