import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import FuncHeader from "components/FuncHeader/FuncHeader";
// qr code
import weixinkefu from "assets/user/weixinkefu.jpg";

export default function Kefu() {
  const history = useHistory();
  const {config} = useSelector((state)=>state.user.data);
 

  return (
    <>
      <FuncHeader title={"客服"} goBack={() => history.push("/user")} />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img src={weixinkefu} style={{ marginTop: "60px" }} />
      </div>
      <div>公司电话：{config.find(c=>c.name == "CVA_PHONE")?.value}</div>
      <div>公司邮箱：{config.find(c=>c.name == "CVA_EMAIL")?.value}</div>

    </>
  );
}
