import React, { useState, useEffect } from "react";
import * as styles from "./rightPanel.module.scss";

import banner01 from "assets/category/banner01.jpg";
import banner02 from "assets/category/banner02.jpg";
import banner03 from "assets/category/banner03.jpg";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { BASE_URL } from "../../../utils/request/config";
import _ from "lodash";
import { Carousel } from "zarm";

const ITEMS = [banner01, banner02, banner03];

const contentRender = () => {
  return ITEMS.map((item, i) => {
    return (
      <div className="carousel__item__pic" key={+i}>
        <img
          src={item}
          alt=""
          draggable={false}
          style={{ width: "100%", marginTop: "10px" }}
        />
      </div>
    );
  });
};

const SectionComponent = () => {
  const [userProfile, setUser] = useState();

  const history = useHistory();
  let isAuthed = useSelector((state) => state.auth.isAuthed);

  let token = useSelector((state) => state.auth.token);
  let userId = useSelector((state) => state.auth.userId);
  const [allProducts, getAllProducts] = useState([]);
  const [allUsers, setAllUsers] = useState([]);

  useEffect(() => {
    if (allProducts) {
      getAllCategory();
    }
  }, []);
  // get category to render list
  function getAllCategory() {
    const config = {};
    axios
      .get(BASE_URL + "/shop/rptProduct/all", config)
      .then((response) => {
        if (response.status === 400 || response.status === 500)
          throw response.data;
        if (response.data.data) {
          // const groups = _.groupBy(response.data.data, 'categoryId');
          let users = [];
          response.data.data.forEach((n) => {
            if (!users.includes((u) => u.id == n.user.id)) {
              users.push(n.user);
            }
          });
          console.log(users);
          setAllUsers(users);
          const groups = _(
            response.data.data.filter((n) => n.productStatus == "ACTIVE")
          )
            .groupBy(`category.name`)
            .map((name, category) => {
              const items = name.map((item) => item);
              return {
                category: items[0].category,
                items: items,
              };
            })
            .value();

          getAllProducts(groups);
        }
      })
      .catch((err) => {
        throw err[1];
      });
  }

  function getImg(str) {
    if (!str) {
      return "";
    }
    const rt = str.split("&&");
    return rt[0];
  }

  return (
    <>
      {/*<img*/}
      {/*  id={"全部商品"}*/}
      {/*  className={styles["banner-img"]}*/}
      {/*  alt="icon"*/}
      {/*  src={banner01}*/}
      {/*  style={{ width: "100%", marginTop: "10px" }}*/}
      {/*/>*/}
      {/*<Carousel>{contentRender()}</Carousel>*/}
      <div key={-1} style={{ marginTop: "20px" }}>
        <div className={styles["section-title"]}>
          <span
            style={{
              backgroundColor: "white",
              borderRadius: 6,
              padding: 6,
            }}
            id={"all user"}
            title={"全部商铺"}
          >
            全部商铺
          </span>
        </div>
        <div className={styles["product-container"]}>
          {allUsers.map((user) => {
            return (
              <div key={user.id} className={styles["product-box"]}>
                <img
                  className={styles["product-box-img"]}
                  alt="icon"
                  src={
                    user.shopAvatarImage ||
                    "https://cva-api.s3-ap-southeast-2.amazonaws.com/mediaConfig/B22698B8-42A2-4115-9631-1C2D1E2AC5F7-SHOP-AVATAR/截屏2021-12-04 下午4.37.43.png"
                  }
                />
                <span style={{ fontSize: "12px" }}>{user?.shopName}</span>
                <span style={{ fontSize: "12px" }}>
                  <span>主营业务：{user?.shopDescription}</span>
                </span>
                <span style={{ fontSize: "12px" }}>
                  <span>分享特色:{user?.shopFeature}</span>
                </span>
                <span style={{ fontSize: "12px" }}>
                  <span>公司网站:{user?.companyLink}</span>
                </span>
                <span style={{ fontSize: "12px" }}>
                  <span>公司名:{user?.companyName}</span>
                </span>
                <span style={{ fontSize: "12px" }}>
                  <span>店铺地址：{user?.address}</span>
                </span>
                <span style={{ fontSize: "12px" }}>
                  <span>电话：{user?.phoneNumber}</span>
                </span>
                <span style={{ fontSize: "10px" }}>
                  负责人姓名：
                  {user?.userName}
                </span>
              </div>
            );
          })}
        </div>
      </div>
      {allProducts
        ? allProducts.map((section) => (
            <div key={section.category.id} style={{ marginTop: "20px" }}>
              <div
                style={{
                  backgroundImage: `url(${getImg(
                    section.category.categoryImage
                  )})`,
                }}
                onClick={() =>
                  history.push("/categoryList?id=" + section.category.id)
                }
                className={styles["section-title"]}
              >
                <span
                  style={{
                    backgroundColor: "white",
                    borderRadius: 6,
                    padding: 6,
                  }}
                  id={section.category.name}
                  title={section.category.name}
                >
                  {section.category.name}
                </span>
              </div>
              <div className={styles["product-container"]}>
                {section.items.slice(0, 9).map((item) => (
                  <div
                    key={item?.id}
                    className={styles["product-box"]}
                    onClick={() =>
                      history.push("/rptproductdetail?itemId=" + item?.id)
                    }
                  >
                    <img
                      className={styles["product-box-img"]}
                      alt="icon"
                      src={getImg(item?.productImages)}
                    />
                    <span style={{ fontSize: "12px" }}>{item?.name}</span>
                    <span style={{ fontSize: "12px" }}>
                      <span style={{ color: "red" }}>{item?.price}</span>
                    </span>
                    {/*<span style={{ fontSize: "12px" }}>*/}
                    {/*  积分：<span style={{ color: "red" }}>{item?.price*item?.user?.rptRate}</span>*/}
                    {/*</span>*/}
                    <span style={{ fontSize: "10px" }}>
                      商家：{item?.companyName}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          ))
        : null}
    </>
  );
};

export default function RightJiFenPanel() {
  return (
    <div className={styles["wrapper"]}>
      <SectionComponent />
    </div>
  );
}
